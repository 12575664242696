<template>

  <div id="app" class="d-flex flex-column position-relative"
    :class="backgroundPage"

  >

    <top-bar
      :showNavMenu="showNavMenu"
      v-if="!onlyForm"
      :scrolly="scrolly"
      @close="showNavMenu=false;"
      @open="showNavMenu=true"
      :mobileMode="mobileMode"
      @openChecklist="toggleChecklist();showNavMenu=false;"
    />

    <div
      id="main-column" ref="mainColumn"
     class="flex-grow-1 h-100 overflow-auto d-flex flex-column position-relative"  >

      <router-view
        :scrolly="scrolly"
        :mobileMode="mobileMode && !onlyForm"
        :onlyForm="onlyForm"
        @scrollTop="scrollToTop"
        @toggle-mobile="toggleMobile"
        @show-nav="displayNav"
        @openChecklist="toggleChecklist"
      />

      <payment-dialog v-if="$store.state.paymentDialog">

      </payment-dialog>

      <insurance-checklist v-if="showIsuranceChecklist"
        @close="toggleChecklist"
        @showNavMenu="displayNav"
      />

    </div>

    <div  class="position-absolute notifier pt-5">
      <transition-group name="message-pods">
        <div v-for="m in messages" :key="m.key"
          class="message-pod bg-white shadow position-relative rounded p-3 m-2"
          :class="'mborder-'+m.type"
        >
            {{m.message}}
        </div>

      </transition-group>
    </div>
  </div>
</template>

<script>
import TopBar from './components/special/TopBar'
// import Footer from './components/special/Footer'
import PaymentDialog from './components/special/PaymentDialog'
import moment from 'moment'
import InsuranceChecklist from './views/InsuranceChecklist.vue'

export default{
  components:{
    TopBar,
    PaymentDialog,
    InsuranceChecklist
    // Footer
  },
  data(){
    return{
      showNavMenu:false,
      scrolly:0,
      mobileMode:true,
      showIsuranceChecklist: false,
    }
  },
  computed:{
    messages(){
      return this.$parent.messages
    },
    onlyForm(){
      return !!this.$route.query.formOnly
    },
    isFormPage(){
      return this.$route.name == 'form'
    },
    backgroundPage(){
      const pageName = this.$route.name;
      return pageName+'-page'

    }
  },
  created(){
    if(!this.$store.state.stories.length){
      this.fetchBlogs();
    }
  },
  methods:{
    async fetchBlogs(){
      function prettifyTime(s){
        let time = s.split(' ')[0]
        time = moment(time,'YYYY-MM-DD').format('Do MMM YYYY')

        return time
      }
       try {
        // this.loadingBlogs = 1
        const url = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@protectmewell'
        const res = await this.$axios.post(url,{email:this.email});
        let stories = res.data.items
        stories = stories.map(s => ({...s,time:prettifyTime(s.pubDate),descriptoin:null,content:null})).slice(0,3)
        this.$store.commit('setStories',stories)

        // this.$success(res)
        // this.loadingBlogs = 0

      } catch (err) {
        // this.$error(err)

        // this.loadingBlogs = -1
      }

    },
    toggleMobile(val){
      if(val == null){
        this.mobileMode = !this.mobileMode
      }else{
        this.mobileMode = val;
      }
    },
    displayNav(){
      setTimeout(() => {
        this.showNavMenu = true
      }, 50);
    },
    reloadImages(){

      const page = this.$route.name;
      let src = '/images/landing/'+page+'.jpg'

      //
      src = src.replace('_low','')

      const img = new Image()

      img.src = src;
      //
      //
      img.onload = () => {
        src = `url("${src}")`
        this.$el.style.setProperty('--'+page+'-image',src);
        //
      }
    },
    scrollToTop(){
      this.$refs.mainColumn.scrollTop = 0
    },
    loadChatBot() {
      if (window.innerWidth > 600) {
        var s1=document.createElement('script');
        s1.id = "chatbot";
        var s0=document.getElementsByTagName('script')[0];
        s1.async=true;
        s1.charset='UTF-8';
        s1.src='https://us.floatbot.ai/portal/chatbot/?botId=604cc067429f9b56f83efa75';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      }
    },
    toggleChecklist(){
      // if(!this.showIsuranceChecklist){
      //   history.pushState(
      //     {},
      //     null,
      //     '/checklist'
      //   )
      // }
      this.showIsuranceChecklist = !this.showIsuranceChecklist
    }
  },
  mounted(){
    if(this.$route.path.includes('checklist')){
        this.showIsuranceChecklist = true
    }

    this.reloadImages();
    this.$refs.mainColumn.addEventListener('scroll',(e)=>{
      this.scrolly = e.target.scrollTop
    })
    this.loadChatBot();
    window.addEventListener('resize',this.loadChatBot)
  },

  watch:{
    '$route.path'(){
      this.reloadImages();
      this.showIsuranceChecklist = false
    }
  }

}

</script>


<style>


:root{
  --text-color: rgb(41, 81, 107);
  --primary: rgb(0,0,0);
}
.my-text{
  color: var(--text-color);
}
.text-pri{
  color: var(--pri);
}
.text-sec{
  color: var(--blue);
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css?family=Arvo:400,700&display=swap');


#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Roboto', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  --special-font: 'Arvo', serif;
  --logo-font: 'Roboto', sans-serif;
  --darkblue : #3d5f9f;
  --darkerblue: #2F3676;
  --darkgreen: #55ADC6;

  --blue: rgb(100, 190, 255);
  --green: #50E3C2;
  --red: #FF5881;
  --pri:  var(--darkerblue);
  --pri-light:  var(--blue);

  --sec:  var(--darkgreen);
  --ter:  var(--blue);

    --scolor: var(--blue);
        --lightergray: rgb(211, 211, 211);

        --pink: rgb(248, 136, 155);

  --text-color: #083A75;
  --text-color2: #2F3676;
  --normalgray: #787878;
  --lightgray: #C4C4C4;
  --yellow: rgb(255, 208, 0);
  --bluegray: #9FB0C6;
  --mygray: rgb(165, 181, 190);
  --black: #000000;
  background-color: white;
  height: 100vh;
  width: 100vw;
  color: var(--text-color);
  overflow-x: hidden;

  --faq-image: url('/images/landing/faq_low.webp');
  --about-image: url('/images/landing/about_low.webp');

}
button{
  color: var(--text-color);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


::-webkit-scrollbar {
    background-color: rgb(217, 226, 230);

    width: 8px;
  }
::-webkit-scrollbar-thumb{
    background-color: rgb(133, 154, 173);
    border-radius: 8px;
    display: relative;

    /* box-shadow: 0 0 8px black; */
  }

.logo-font{
  font-family: var(--logo-font);
}
.special-font{
  font-family: var(--special-font);
}
.bg-pri{
  background-color: var(--pri);
}
.bg-sec{
  background-color: var(--sec);
}
.bg-green{
  background-color: var(--green);
}
.bg-red{
  background-color: var(--ter);
}
.bg-red{
  background-color: var(--red);
}

.bg-pri-light{
  background-color: var(--pri-light);
}
  .bg-darkblue{
    background-color: var(--darkblue);
  }
  .bg-darkgreen{
    background-color: var(--darkgreen);
  }
  .bg-bluegray{
    background-color: var(--bluegray);
  }
  .bg-gray{
    background-color:lightgray;
  }
 .bg-blue{
    background-color: var(--blue);
  }

  .bg-green{
    background-color: var(--green);
  }
  .text-darkblue{
    color: var(--darkblue);
  }
  .text-red{
    color: var(--red);
  }
  .text-bluegray{
    color: var(--bluegray);
  }
  .text-blue{
    /* color: var(--blue); */
    color: rgb(20, 179, 207);
  }
  .text-green{
    color: var(--green);
  }
  .text-yellow{
    color: var(--yellow);
  }
  .text-light-gray{
    color: rgb(129, 150, 158);
  }
  .text-gray{
    color: var(--normalgray);
  }
  .fot-weight-500{
    font-weight:500;
  }
  .form-page{
      background-image: url('/images/background.svg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      background-position: center;
  }
  .about-page p {
    //color: darkslategray;
  }

  .faq-page,.about-page,.form-page,.home-page{
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      background-position: left;
  }
  .faq-page{
      background-image: var(--faq-image);
  }
  .home-page{
      /* background-image: url('/images/landing/father.png'); */
      /* background: rgb(235, 237, 236); */
      /* background-position: top;
      background-attachment: fixed;
      background-size: contain; */
      /* background: linear-gradient(to right,rgb(232, 234, 231),rgb(232, 234, 231)); */
      /* background: linear-gradient(to bottom,rgb(241, 241, 239),rgb(241, 241, 239)); */
  }
  .about-page{
      //background-image: var(--about-image);
      background: white;
  }

  ::placeholder{
    color: lightsteelblue;
  }

  .take-me{
    background-color: rgb(52, 230, 230);
    color: var(--text-color);
  }
  .take-me:focus{
    outline: none;
  }
  .outline-0:focus{
    outline: none;
  }

  .my-overlay{
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.6);
    /* transition: display 0.5s; */
  }

  .notifier{
    right:0;
    bottom:0;
    min-height:100px;
    min-width: 50px;
    /* To bring notifications over the chat bubble thing,which has z-index of 9999 */
    z-index: 10001;
    /* background-color: rgba(0, 0, 0, 0.1); */
  }
  .message-pod{
    border-left: 6px solid var(--blue);
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .fade-down-enter-active,.fade-down-leave-active{
    transition: opacity 0.4s,transform 0.4s;
  }
  .fade-down-enter,.fade-down-leave-to{
     opacity: 0;
     transform: translateY(-12px);
  }


  .message-pods-enter-active,.message-pods-leave-active{
    transition: all 0.4s;
  }
  .message-pods-enter,.message-pods-leave-to{
     transform: translateX(100%);
  }
  .mborder-success{
    border-color: var(--green);
  }
   .mborder-warning{
    border-color: var(--yellow);
  }
   .mborder-error{
    border-color: var(--red);
  }

a{
 cursor: pointer;
}
.cursor-pointer{
  cursor: pointer;
}
.my-link{
  text-decoration: underline;
}

  .fade-in{
    animation: fade-in 1.4s;
    position: relative;
  }
  .fade-in-slide{
    animation: slide-fade-in-animation 1.6s;
    position: relative;
  }

  @keyframes slide-fade-in-animation{
    0%{
      opacity:0;
      transform: translateX(-20px);
    }
    100%{
      opacity:1;

    }
  }
.logo-text{
    color:rgb(20, 179, 207);
    font-weight: bold;
}

#main-column {
  scroll-behavior: smooth;
}
.rotating{
  animation: rotate 0.8s infinite;
}
@keyframes rotate{
    from{}
    to{transform: rotate(360deg);}
}
.vloading,.loading{
        position: relative;
        animation: rotate 0.8s infinite ;
        font-size: 3.0em;
        color: var(--blue);
  }
  .vloading{
      animation: flip-horizontal 1.2s infinite;
    }
  @keyframes flip-horizontal {
      from{}
      to{transform: rotateY(360deg);}
  }
.rounded-complete{
  border-radius: 24px;
}
.border-green{
  border: 2px solid var(--green);
  color: var(--green);
}
.border-gray{
  border: 1px solid darkgray;
}
.border-2{
  border-width: 2px;
}
.hover-light:hover{
  opacity: 0.9;
}
.opacity-0{
  opacity: 0;
}
 .form-container{
        /* --scolor: rgb(40,200,2555); */

        background-color: #F8F9FF;
         box-shadow: 0 0 12px rgb(0,0,0,0.2);
    }

input{
        border:none;
        color: lightslategray;
        border-bottom: 2px solid var(--lightergray);
        outline: none;
        font-size: 1.2em;
    }
    .form-text-input{

        padding-bottom: 8px;
        width: 100%;
    }
    .form-text-input:focus{
        border-color: var(--scolor);
        color: var(--scolor);
    }

@keyframes fade-in{
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}
@keyframes fade-right{
   0%{
      opacity:0;
      transform: translateX(20px);
    }
    100%{
      opacity:1;

    }
}
@keyframes fade-left{
   0%{
      opacity:0;
      transform: translateX(-20px);
    }
    100%{
      opacity:1;

    }
}
@keyframes fade-above{
   0%{
      opacity:0;
      transform: translateY(-20px);
    }
    100%{
      opacity:1;

    }
}
@keyframes fade-below{
   0%{
      opacity:0;
      transform: translateY(20px);
    }
    100%{
      opacity:1;

    }
}


.rounded-extra{
  border-radius: 8px;
}
.rounded-huge{
  border-radius: 16px;
}
.rounded-complete{
  border-radius: 160px;
}

#app button{
  outline: none;

}
button:active{
  box-shadow: inset 0 0 6px rgba(63, 63, 63, 0.3);;
  filter: brightness(0.8);
}
button:focus{

  outline: none;
}

.std-button{
  background-color: var(--green);
  color: var(--text-color);
  padding: 8px 18px;
  font-size: 0.9em;
  border:none;
  border-radius: 4px;
  font-family: var(--logo-font);
  font-weight: bold;
}
.border-pri{
  border: 2px solid var(--darkblue);
}
.border-sec{
  border: 2px solid var(--blue);
}

.hover-enlarge:hover{
  transform: scale(1.2);
}

.flex-grow-1{
  flex: 1 1 0%;
}

.d-flex:not(.overflow-auto){
  flex-shrink: 0;
}

.text-medium{
  font-size: 0.9em;
}

.hover-dark:hover{
  filter: brightness(0.8);

}

canvas,
img {
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
}

</style>
