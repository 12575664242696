<template>
  <div class="  w-100 h-100 position-fixed overlay-frosted"  ref="div"
      style="left:0;top:0;z-index:150;"
      >
    
   
            <slot/>
    </div>
  
</template>

<script>
export default {

    mounted(){
        const div = this.$refs.div
        // const cs = getComputedStyle(div)
        // if(!cs.backdropFilter){
            // div.style.boxShadow = '0 0 900px rgba(0,0,0,0.6) inset'
            div.style.backgroundColor = 'rgba(190,190,190, 0.76)'
        // }
    }

}
</script>

<style>
.overlay-frosted{
    /* backdrop-filter: blur(20px); */
    background-color: rgba(10, 10, 10, 0.64);
    
}
</style>