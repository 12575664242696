
<template>

    <div class="d-flex flex-column position-fixed bg-white overflow-hidden"
        id="checklist-dialog"
    >
        <div class="d-flex align-items-center p-3  border-bottom "
                style="z-index:3;background-color:#E4EAF670;"
        >
            <img src="/images/ham.svg" height="18px" 
            style="transform:translate(-80%,0);filter:brightness(0.2);"
                @click="menuClicked" class="d-md-none"
            />
            
            <h5 class=" ml-3 m-0   " style="color:#2F3676;">
                Insurance checklist
            </h5>
     
            <popup-button 
                class="ml-auto "
                :noTriangle="true"
                :simple="true"
            >
                <template v-slot:button>
                    <button class=" ml-auto border-0 bg-transparent pl-3 pr-2 rounded"
                    >
                        <i class="fa fa-ellipsis-v " />
                    </button>
                </template>

                <template v-slot:default >
                    <div class="d-flex flex-column p-2 rounded overflow-hidden" style="width:128px;border:none;">
                        <button class="border-0 bg-white mb-2 hover-dark p-1 rounded "
                            @click="resetForm"
                        >
                            Reset Form
                        </button>
                        <button class="border-0 bg-white p-1 rounded " @click="$emit('close')">
                            Close Form
                        </button>
                    </div>
                    
                </template>

            </popup-button>
        </div>

        <div  
            :style="{height:'2px',backgroundColor:'#3D5F9F22',position:'relative'}"
        >
            <div  :style="{width:(progress)+'%',backgroundColor:'#3D5F9F',top:'0px',left:'0px',position:'absolute',height:'100%'}" />
        </div>

        <div class="descriptor d-flex align-items-center bg-white "
            style="z-index:2;box-shadow: 0 0 6px #dadada"
        >
            <img src="/images/result/term.svg" height="42px" alt="">
            <div class="text-left ml-3">
                <p class="text-pri small m-0 p-0 ">Analysis for</p>
                <p class="text-pri-dark m-0 font-weight-bold ">Term Insurance.</p>
            </div>
            <span class="ml-auto font-weight-bold "
                style="border: 1px solid var(--pri);border-radius:32px;font-size:12px;padding: 3px 12px;"
            >
                {{num_answers}}/{{question_bank.length}}
            </span>

        </div>

        <div class="d-flex flex-column-reverse flex-grow-1 p-3 overflow-auto"
        style="box-shadow: 0 2px 6px lightgray;z-index:1;"
        >
      
            <!-- <rating-meter :value="7" label="Protection" />  -->

            <result-viewer :result="result" v-if="result" class="fade-in"
                v-fade-in="{delay:800+200,}"
            >
            </result-viewer >

            <spinner :submitting='submitting' :result="result" v-if="submitting || result" />

            <div class="d-flex my-2 " v-if="submitting">
                <div :class="`answer-bubble text-dark px-3 p-2 mb-2 ml-auto font-weight-500 bg-green `"
                    style="border-top-right-radius:0;"
                >
                    Sumbit
                </div>
            </div>

            <div class="d-flex my-2 " v-if="questions_completed">
                <div class="question-bubble text-dark  bg-warning text-white mr-2 d-flex align-items-center justify-content-center " 
                        style="border-radius:50%;width:30px;height:30px;"
                >
                    !
                </div>
                <div class="question-bubble bg-warning p-2 px-3 text-dark " style="border-top-left-radius:0;"
                    v-fade-in="{delay:400+200,}"
                >
                    Submit the form?
                </div>
            </div>

            <div class="d-flex flex-column " v-for="(q) in questions" :key="q.q">
                <div class="d-flex ">
                    <div class="question-bubble text-white mr-2 d-flex align-items-center justify-content-center " 
                        style="border-radius:50%;width:30px;height:30px;"
                    >
                        <p class="small mx-0 p-0 h-50 " style="margin-bottom:6px">
                        {{q.id}}
                        </p>
                    </div>
                    <div  
                        class="question-bubble d-flex align-items-center flex-grow-1 text-left px-3 p-2 mb-2 "
                        style="border-top-left-radius:0;font-size:14px;"
                    >
                        {{q.q}}
                    </div>
                </div>
                
                <div :class="`answer-bubble  px-3 p-2 mb-2 ml-auto font-weight-500 ${answers[q.i]?'bg-green':'bg-red'} `"
                    style="border-top-right-radius:0;"
                    v-if="num_answers>q.i"
                >
                    {{answers[q.i]?'Yes':'No'}}
                </div>

            </div>
            
        </div>


        <div class="controls-row"
            v-if="!!result"
        >
            <button class="bg-red pill-button "  @click="closeDialog()" >
                <i class="fa fa-times " />
            </button>
            <a :href="result.pdfUrl" class="text-decoration-none text-white" download="report.pdf">                
                <button class="text-medium text-white pill-button ml-3 text-uppercase " 
                    style="background-color:var(--pri);"
                >
                    Download report
                </button>
            </a>
            
        </div>        
        <div class="controls-row"
            v-else-if="questions_completed"
        >
        
            <button class="bg-red pill-button " @click="closeDialog()" >
                <i class="fa fa-times " />
            </button>
            <button class="bg-green pill-button ml-3" @click="submitForm()">
                Submit
            </button>
            
        </div>
        <div class="controls-row" v-else
        >
            <button class="bg-red pill-button " @click="answerQuestion(false)" >
                No
            </button>
            <button class="bg-green pill-button ml-3" @click="answerQuestion(true)">
                Yes
            </button>
     
        </div>

        
    </div>
  
</template>

<script>
import PopupButton from '../components/common/PopupButton.vue'
import texts from '../texts'
import Vue from 'vue'
import RatingMeter from '../components/special/RatingMeter.vue'

Vue.component('result-viewer', {
  components:{
      RatingMeter
  },
  render: function (h) {
      console.log(h)
    return <div class='' style={{height:'380px'}}>
        

        <div class='d-flex '  style={{margin:'60px 0px '}}>
            <div class='flex-grow-1'>
                <rating-meter value={this.result.protectionScore} label="Protection" /> 
            </div>
            <div class='flex-grow-1'>
                <rating-meter value={this.result.valueScore} label="Value for money"/> 
            </div>

        </div>

        <div class="d-flex">

        </div>

        <p class='text-pri small '>
            {texts.insuranceChecklist.ending}
        </p>
    </div>
    
  },
  props: {
    result: {
      type: Object,
      required: true
    },
    level:{}
  },
  data(){
      return {
          points:[
              'apple is real',
              'pig bitch vag'
          ]
      }
  }
})

Vue.component('spinner',{
    render(h){
        console.log(h)
        return <div class='d-flex justify-content-start '
        >
            {this.submitting ? 
            <div class="pure-spinner my-2"  />
            :
            <span v-fade-in="{delay:400}">
                <i class='fa fa-check-circle mr-4 mt-4 fade-in' style={{fontSize:'1.5em'}} />
                Your recommendation provides...
            </span>
            }

        </div>
    },
    props:{
        submitting:{},
        result:{}
    }
})

const types = {
    term:{url: 'generateTermChecklist'},
    critical_illness:{},
    retirement:{},
    health:{},
}

export default {
  components: { PopupButton },
    computed:{
        num_answers(){
            return this.answers.length
        },
        progress(){
            return (this.answers.length/this.question_bank.length)*100 + 2
        },
        questions_completed(){
            return this.answers.length >= this.question_bank.length
        }
    },
    data(){
        return{
            answers:[],
            question_bank: texts.insuranceChecklist.questions.map((q,i) => ({...q,i,id:this.getQuestionNumber(i+1)})),
            questions:[],

            questionNumber: 0,
            result: null,

            submitting: false,

        }
    },

    props:{
     isMobile : {type:Boolean},
    },
    mounted(){
        this.questions.push(this.question_bank[0])
        const query = this.$route.query
        let type = (query?.type) || 'term'
        console.log(`type= ${type}`)
        console.log(types[type])

        document.addEventListener('keyup',(e)=>{
            if(e.key == '`'){
                    const lack = this.question_bank.length -   this.answers.length
                    for(let i =0;i<lack;i++){
                        this.answers.push(true)
                    }
            }
        })
    },
    methods:{
        async submitForm(){
            this.submitting = true

            const formData = new FormData()
            formData.append('key','U8eZ1ALf44kiYQRzQJIJCb42')
            
            this.answers.forEach((a,i)=>{
                i += 1
                const ind = i < 6 ? i : (6+'_'+(i-6+1))
                formData.append(`q${ind}`,a?'Yes':'No')
            })
  
            try {

                const url = this.$base_Node_Url+`generic/generateTermCheckList`
                const res = await  this.$axios.post(url,formData)
                this.result = res.data

                location.href =  res.data.pdfUrl
            } catch (error) {
                this.$error(error)
                console.log(error)
            } finally{
                this.submitting = false

            }

        },
        resetForm(){
            this.questions = [this.question_bank[0]]
            this.answers = []
            this.result = null;
            this.submitting = false
        },
        async answerQuestion(ans){
            if(this.answers.length>=this.questions.length){return}
            if(this.answers.length < this.questions.length){
                this.answers.push(ans)
            }
            await this.$wait(900)
            if(this.question_bank.length > this.questions.length){
                this.questions.unshift(this.question_bank[this.questions.length])
            }
        },
        getQuestionNumber(i){
            if(i < 6){return i}
            else{
                return `${6} ${String.fromCharCode(65+i-6)}`
            }
        },
        closeDialog(){
            this.$emit('close')
        },
        menuClicked(){
            this.$emit('showNavMenu')
        }
    }
}
</script>

<style>

#checklist-dialog{
    width: 420px;
    height: 640px;
    /* To bring this over the chat bubble thing,which has z-index of 9999 */
    z-index: 10000;
    
    bottom: 12px;
    left: 12px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--pri-light);
    box-shadow: 0 0 8px #00000055;
}

@media screen and (max-width: 520px) {

    #checklist-dialog{
        width:100%;
        height: 100%;
        bottom:0;left:0;
        
        border:none;
        border-radius: 0;
    }
}

.pill-button{
    border-radius: 48px;
    padding: 4px 16px;
    border: 2px solid white;
    font-weight: bold;
    color: var(--pri);
    font-size: 18px;
}

.question-bubble{
    background-color: #3D5F9F;
    color: white;
    border-radius: 12px;
    animation: fade-in-animation 0.8s;
    /* animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0; */
}
.answer-bubble{
    color: var(--pri);
    border-radius: 12px;
    animation: fade-in-animation 0.8s;
}
  @keyframes fade-in-animation{
    0%{
      opacity:0;
    }
    100%{
      opacity:1;
      
    }
  }



.descriptor{
    padding: 20px 20px;

    background: linear-gradient(to top,rgb(241, 249, 255),white);
    border-bottom: 2px solid rgb(211, 226, 245);


}

.controls-row{

    display: flex;
    justify-content: flex-end;

    align-items: center;
    padding: 18px;
    background-color:#f1f1f1;

}

.pure-spinner-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
.pure-spinner:after {
    --color: var(--pri);
    content: " ";
    display: block;
    width:  24px;
    height: 24px;
    margin: 2px 8px;
    border-radius: 50%;
    border: 4px solid var(--color);
    border-color: var(--color)var(--color)var(--color) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

.rating-meter {
    --size : 96px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;

    border: 3px dotted red;
/* 
    border: 5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #743ad5, #F66161); */

}


</style>