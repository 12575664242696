import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)
const vm = Vue.prototype

export default new Vuex.Store({
  state: {
    user:null,
    client_id:'',
    session_id:'',
    reports_unlocked:false,
    paymentDialog:null,
    stories:[],

    planData:null,
    formData:{},
    payment_details:null,
    credits: 0,
    showSignIn: 0,
    showCreditScreen: false,
    refreshGetStarted: false,
    creditLinkCredits: 0,
    creditValidity: null,
    isRetail:false,
    gift_card_unlocked: false,
    link_url: null,
    isGiftCard: false,
    giftCardQRLink: null,
    showGiftCardPopup: false
  },
  getters:{
    allow_reports(state){
      // return state.reports_unlocked || (state.user && state.user.credits)
      const plan = state.planData && state.planData.recommended_portfolio
      return plan && (plan.hi || plan.ci_long)
    }
  },
  mutations: {
    setStories(state,payload){
      state.stories = payload
    },
    setShowSignIn(state,payload){
      state.showSignIn = payload
    },
    setPaymentDetails(state,payload){
      state.payment_details = payload
    },
    showPaymentDialog(state,payload={}){
      // if(state.planData){
        state.paymentDialog = payload
      // }
    },
    unlockReport(state){
      state.reports_unlocked = true;
    },
    unlockGiftCard(state, status){
      state.gift_card_unlocked = status;
    },
    setPlanData(state,plan){
      state.planData = plan
    },
    isretail(state,status){
      state.isRetail = status
    },
    setFormData(state,fdata){
      state.formData = fdata
    },
    login(state,data){
      if (data != null && data.data!=null && data.data.session_id != null) {
          state.user = data.data
          console.trace('login',data)
          localStorage.setItem('puser',JSON.stringify(data.data))
          localStorage.setItem('token',data.token)
          if (data.token) {
            Axios.defaults.headers.common.Authorization = data.token;
          }
      }
    },
    logout(state){
     // this.$cookie.set('ifaUser',null, '30d')
     console.trace('logout called','')
      state.user = null
      localStorage.setItem('puser',null)
      localStorage.setItem('token',null)
      // delete Axios.defaults.headers.common.Authorization
    },
    set_client_id(state,id){
      state.client_id = id
    },
    set_session_id(state,id){
      state.session_id = id
    },
    setShowCreditScreen(state, show) {
      state.showCreditScreen = show
    },
    setRefreshGetStarted(state, status) {
      state.refreshGetStarted = status
    },
    setGiftCardUnlocked(state, show) {
      state.gift_card_unlocked = show
    },
    setGiftCardQRLink(state, value) {
      state.giftCardQRLink = value
    },
    setGiftCardPopup(state, value) {
      state.showGiftCardPopup = value
    },
  },
  actions: {
    showReportUnlockPayment(context){
      const payload = {
        payment_type:'unlock_report',
      }
      context.commit('showPaymentDialog',payload)
    },
    addCreditPayment(context, plan){
      const payload = {
        payment_type:'add_credit',
        plan: plan
      }
      context.commit('showPaymentDialog',payload, plan)
    },
    showGiftCardPayment(context, details, emailId){
      const payload = {
        payment_type:'gift_card',
        payment_details: details,
        emailId: emailId
      }
      context.commit('showPaymentDialog',payload)
    },
    signIn(){

    },
    signOut(){

    },
    async fetchUser(context){
      try{
        if (context.state.user) {
          const res = await vm.$axios.get(vm.$base_Node_Url+'ifaUser/getByUserId/'+ context.state.user.userId)

          const user = res.data;
          context.state.user.credits = res.data.credits;
          return user
        }
      }catch(err){
        context.state.fresh = false;
        throw err
      }

    },
    async getCreditLinkByUrl(context, payload){
      try{
          const res = await vm.$axios.get(vm.$base_Node_Url+'credit-link/getCredit/'+ payload.creditLinkId + '/' + payload.creditUrl + '/' + payload.isGiftCard)

          const data = res.data;
          if (res.data.link_url && res.data.link_url.length > 0) {
            context.state.creditLinkCredits = res.data.link_url[0].credits;
            context.state.creditValidity = res.data.validity.valid_till;
            context.state.link_url = res.data.link_url[0].url;
            context.state.isGiftCard = false;
            if (res.data.type && res.data.type === 'GIFT') {
              context.state.isGiftCard = true;
            }
          }
          return data
      }catch(err){
        context.state.fresh = false;
        throw err
      }

    }
  },
  modules: {
  }
})
