<template>
  
  <div class="d-flex flex-column 
  align-items-center h-100
  ">
    <div class="my-auto  " >

        <h1 class="display-1 text-warning">404</h1>
        <h4 class="text-darkblue ">The page you are looking for doesn't exist.</h4>
    </div>
    <Footer class="mt-2" />

  </div>

</template>

<script>
import Footer from '../components/special/Footer'

export default {
    components:{
        Footer
    }
}
</script>

<style>

</style>