<template>
<div class="position-relative">
<ValidationProvider :rules="crules" v-slot="{errors}">
    <div class="d-flex justify-content-between p-0">
        <p class="small text-muted text-left m-0 ml-1 mb-1" v-if="label">{{label}}</p>
        <p class="small text-danger text-left mt-1 mb-1 ml-auto">{{errors[0]||'.'}}</p>
    </div>
   <input type="text" 
    ref="inp"
    class="form-text-input" 
    :value="value"
    @input="handleInput"
    @change="handleChange"
    :style="{borderColor:errors[0]?'rgb(255,120,30)':''}"
    v-bind="$attrs"
    >

<!--      
    <p class="small text-danger position-absolute" style="top:-12px;left:0;">
        {{label}}
    </p> -->
</ValidationProvider>
</div>
</template>

<script>
import {ValidationProvider} from 'vee-validate'
export default {
    components:{
        ValidationProvider
    },
    props:{
        value:{},
        type:{type:String},
        min:{type:String,default:'0'},
        max:{type:String,},
        readOnly:{type:Boolean,default:false,},
        label:{type:String,default:''},
        rules:{type:String,default:'required'}

    },
    computed:{
        crules(){
            let crules = this.rules
            if(this.type){
                let type = this.type
                if(type == 'text'){
                    type = 'alpha'
                }
                else if(type == 'number'){
                    type = 'numeric|min:'+this.min
                    // if(this.max){type+='|max:'+this.max}
                }else if(type=='password'){
                    type = ''
                }
                crules = crules? crules+'|'+type : type
            }
            return crules
        }
    },
    methods:{
        setValue(value){
            this.$refs.inp.value = value
        },
        handleInput(e){
            if(this.readOnly){return this.$emit('input',e)}
            let val = e.target.value

            if(this.type == 'number'){
                val = val.replace(/[^0-9]+/g,'');
                if(!val){val = '0'}
            }else if(this.type == 'text'){
                val = val.replace(/[^a-z^A-Z^ ]+/g,'');
             }

            this.$emit('input',val)
            this.setValue(val)
        },
        handleChange(e){
            if(this.readOnly||this.type!='number'){return}

            let val = e.target.value
            const value = parseInt(val)
            if(this.min){
                const min = parseInt(this.min)
                val = Math.max(min,value)
            }
            if(this.max){
                const max = parseInt(this.max)
                val = Math.min(max,value)
            }
            this.$emit('input',val)
            this.setValue(val)
        }
    }

    

}
</script>

<style>

</style>