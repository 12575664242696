import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueClipboard from 'vue-clipboard2'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

// import antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'
import '@fortawesome/fontawesome-free/css/all.css'

import axios from 'axios'
import VueHtml2Canvas from 'vue-html2canvas'
// Vue.use(antd)
Vue.config.productionTip = false
// Vue.use(axios)
Vue.prototype.$axios = axios;

Vue.use(VueClipboard)
Vue.use(VueHtml2Canvas);

function isScrolledIntoView(scroller,elem,offset)
{
  const erect = elem.getBoundingClientRect();
  const perect = scroller.getBoundingClientRect();

  var docViewTop = scroller.scrollTop;
  if(scroller!=document){docViewTop = perect.top}
  var docViewBottom = docViewTop + perect.height;

    var elemTop = erect.top;
    var elemBottom = elemTop + erect.height - offset * erect.height;

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}
function setAnim(el,binding){
    const value = binding.value;

    el.style.animation = binding.name+' '+0.9+'s'

    el.style.animationFillMode = 'forwards';
    el.style.animationDelay = (value.delay || 100)+'ms'
}
const fader = {
  inserted: function (el,binding) {
    const value = binding.value;
    const root = document.getElementById('main-column')
    let scroller  =  value.div || root
    if(typeof scroller == 'string'){scroller = document.getElementById(scroller)|| root}


    el.style.opacity = 0

    function handler(){
      if(isScrolledIntoView(scroller,el,value.offset||0.8)){
        setAnim(el,binding)
        scroller.removeEventListener('scroll',handler)
      }

    }

    if(scroller){
      try{
        scroller.addEventListener('scroll',handler)
        handler()
      }catch{
        setAnim(el,binding)
      }
    }else{
      setAnim(el,binding)
    }

  }
}

const fades = ['fade-in','fade-right','fade-left','fade-above','fade-below']
fades.forEach(
  f => {
    Vue.directive(f,fader)
  }
)

Vue.prototype.$wait = (time) => {
  return new Promise(
    (res) => {
      setTimeout(() => {
        res();
      }, time);
    }
  )
}

Vue.prototype.$prettify = (str) => {
  let parts = str.split('_')
  parts = parts.map(p => p[0].toUpperCase()+p.slice(1))
  let result = parts.join(' ')
  return result
}

const toMessage = (err) => {
  const res = err.response
  // const status = res?res.status:null
  const statusText = res?res.statusText:null
  const data = res?res.data:null
  let message = (data&&data.message)?(res.data.message):statusText
  if(/*!store.state.localMode &&*/ !navigator.onLine){
    message = 'You are offline'
  }else if(err.message == 'Network Error'){
    message = 'Could not connect to server'
  }else if(!message){
    message = (err.statusText)||err.message || 'There was an error,Check Console';
  }
  return message;
}

import {extend} from 'vee-validate';

import { required, email,min,max,numeric,alpha_spaces } from 'vee-validate/dist/rules';
import store from './store'
import moment from 'moment'
import crypto from 'crypto'


// No message specified.
extend('email', email);
extend('min', min);
extend('max', max);
extend('alpha',alpha_spaces);
extend('numeric',numeric);


// Override the default message.
extend('required', {
  ...required,
  message: 'This field is required'
});

extend('email', {
  ...email,
  message: 'Please enter a valid email'
});

const user = localStorage.getItem('puser')
console.trace(user)
if(user && user !== "undefined" && user!='' && user!='null'){
  const userData = JSON.parse(user)
  store.commit('login',(userData))
  store.state.user = userData
  const token = localStorage.getItem('token')
  console.trace('token',token)
  if (token) {
    axios.defaults.headers.common.Authorization = token;
  }
}

Vue.prototype.$baseUrl = process.env.VUE_APP_API_URL;
Vue.prototype.$base_Node_Url = process.env.VUE_APP_NODE_API_URL;

Vue.prototype.$refresh = (resource)=>{
  console.log('resource', resource)
  // return store.dispatch('fetchData',resource);
}

const app = new Vue({
  router,
  render: h => h(App),

  data:{
    messages:[]
  },

  store,

  methods:{
    addMessage(data){
      const mess = {
        message: data.message,
        key: Date.now(),
        type: data.type
      }
      this.messages.push(mess)
      setTimeout(() => {
        this.messages.shift();
      }, (data.duration*1000)||5000);
    }
  }
}).$mount('#app')

store.$app = app;

function MD5(string){
  return crypto.createHash('md5').update(string).digest("hex")
}
function genHash(){
  const cwtSecret = 'aca33b9c046b2a50b8c3c54cc0380de8'
  const timeNow = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
  return [MD5(MD5(timeNow)+MD5(cwtSecret)),timeNow]
}

axios.interceptors.request.use((config) => {
  const user = store.state.user
  const clientId = store.state.client_id || (user && user.client_id) || '';
  const [cwt,timestamp] = genHash()
  config.headers.cwt = clientId+'.'+cwt;
  config.headers.timestamp = timestamp;
  if(user){
    config.headers.session_id = user.session_id
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

const notification = {}
const events = ['warning','error','success']
events.forEach(
    e => {
        notification[e]= (data) => {
            data.type = e;
            app.addMessage(data)
        }
    }
)
Vue.prototype.$notification = notification


Vue.prototype.$success = (res) => {



  let message = res;
  if(typeof res != 'string'){



    message = ((res.data && res.data.message) || res.statusText || 'success')
  }
  notification.success({message});
}
Vue.prototype.$warning = (message,options) => notification.warning({message,...options});
Vue.prototype.$info = (message,options) => notification.info({message,...options});

Vue.prototype.$error = (err,options) => {
  let message = (typeof err == 'string'?err:null)||toMessage(err)
  //
  // console.trace(err.stack||err)
  notification.error({message,...options});
}


