

<script>

import Vue from 'vue'


export default Vue.component('rating-meter',{
    render(h){
        const radius = 64
        const stroke = 16
        const pad = 0
        const size = (radius+stroke+pad)* 2
        console.log(h)
 
        // let [x2,y2] = [114,143]
        // const angle = 150 * Math.PI /180

        const normalizedRadius = radius ;
        const circumference = normalizedRadius * 2* Math.PI
        let progress = (this.value+1)*(100/12) //this.progress
        const strokeDashoffset = (circumference - (progress) / 100 * circumference);

        // y2 = size/2 +  Math.cos(angle)* radius
        // x2 = size/2 + Math.sin(angle)* radius
        const unique = this.value

        return <div class='position-relative'>

            <svg width={size} height={size} position="relative"
            >                
            <g fill="none">       
                // gray background
                <circle
                    stroke="#a5d6f955"
                    stroke-dasharray={circumference + ' ' + circumference}
                    style={{transform:'rotate(90deg)',transformOrigin:'50% 50%'}}
                    stroke-width={stroke}
                    fill="transparent"
                    r={radius}
                    cx={radius+stroke}
                    cy={radius+stroke}
                />
                // Define a circle to act as mask to cut the gradient background
                <mask id={'clip_'+unique}>
                    <circle key={this.label}
                        stroke="white"
                        stroke-dasharray={circumference + ' ' + circumference}
                        style={{strokeDashoffset,transform:'rotate(90deg)',transformOrigin:'50% 50%'}}
                        stroke-width={stroke}
                        fill="transparent"
                        r={radius}
                        cx={radius+stroke}
                        cy={radius+stroke}
                    />
                </mask>
                <foreignObject width={size} height={size} mask={`url(#clip_${unique})`}>
                    <div   class='rating-gradient'
                        style="width:100%;height:100%;"
                    />
                </foreignObject>
                // Ticks
                <circle
                    stroke="white"
                    stroke-dasharray={'1.6 31.8'}
                    style={{transform:'rotate(90deg)',transformOrigin:'50% 50%'}}
                    stroke-width={stroke}
                    fill="transparent"
                    r={radius}
                    cx={radius+stroke}
                    cy={radius+stroke}

                />               
            </g>

            </svg>
            <div class='position-absolute text-pri ' style="top:54%;left:50%;transform:translate(-50%,-50%)">
                <h3>
                {this.value}/10
                </h3>
            </div>
             <div class='position-absolute bg-pri rounded-extra text-white p-1 px-3 text-nowrap small' 
                style="bottom:4%;left:50%;transform:translate(-50%,0%);border-radius:32px;"
            >
                    {this.label}
            </div>
         
        </div>
    },
    props:{
        value:{},
        label:{type:String},
    },
    data(){
        return {
            progress: 10
        }
    }
})

</script>


<style >
.rating-gradient{
  background: conic-gradient(from 180deg at 50% 50%, #F66161 0deg, #F4C624 174.38deg, #50E3C2 355deg, rgba(169, 208, 245, 0.2) 359deg);
}

</style>