
<template>
    <popup-button
        :simple="true"
        :noTriangle="true"
        :fromRight="!isMini"
        menuWidth="360px"
        :hoverMode="hover"
    >
        <template v-slot:button>
            <button class="bg-transparent border-0 font-weight-500 text-uppercase "
                :style="{color: invert_color?'var(--pri)':'var(--pri)',fontWeight:'500'}"
                :class=" isMini?'text-sm mr-1':''"
            >
            Products <i class="fa fa-angle-down ml-1"></i>
            </button>
        </template>
        <template>

            <div :class=" `text-left ${isMini?'mini-drop':'dropdown'} ` ">
                <div v-for="(p,i) in products" :key="p.name"
                    @click="handleProductClick(p,i)"
                    class="p-3 pl-3 d-flex align-items-center"
                    :class="(invert_color?'bg-white text-pri ':'bg-pri text-white ')+
                    (p.soon?'disabled ':' cursor-pointer hover-dark ')"
                >
                    <span :class="(p.green?'text-green':'')">
                    {{p.name}}
                    </span>

                    <span class="small " v-if="i==2">
                        <img src="images/free.png" class="ml-2" height="20px" />
                    </span>
                    <!-- <span class="small " v-else-if="i==1">
                        <img src="images/soon.png" class="ml-2" height="16px"/>
                    </span> -->
                </div>

            </div>
        </template>
    </popup-button>
</template>

<script>
import PopupButton from '../common/PopupButton.vue';

export default {
  components: { PopupButton },
  props:{
      hover:{default:true},
      mobileMode:{default:false},
      align:{default:'left'}
  },
  methods:{
        handleProductClick(p,i){
            if(i == 2){
                this.$emit('openChecklist')
            }else if(i == 0){
                this.$emit('getStarted')
            }else if ( i == 1) {
               this.$emit("showGiftCardPopUp") ;
            }
        },
  },
  data(){
      return {
          isLoggedIn: false,
            products: [
                {
                    name:'Comprehensive Insurance Needs Analyser',
                },
                {
                    name:'ProtectMeWell Gift Card',
                },
                {
                    name:'Review your Term Plan - Smart Checklist',green:true
                }
            ]
      }
  },
  computed:{
      invert_color(){
          return this.$route.path.includes('about') && !this.mobileMode
      },
      current_page(){
          return this.$route.path
      },
      isMini(){
          return this.align=='right'
      }
  }

}
</script>

<style scoped>

.disabled{
    pointer-events: none;
}
.disabled > span{
    color: rgb(189, 189, 189);
}
.text-sm{
    font-size: 0.82em;
}
/* .dropdown{
} */
.mini-drop{
    transform: translateX(90px);
}


</style>