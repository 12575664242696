<template>
<div  class="position-relative">

  <div @click="togglePopup"
    @mouseover="handleHover"
    :class="showPop?'popped-up':''" 
    ref="button"
  >
    <slot name="button" />
  </div>
   
 
  <transition name="fade-down">
      <div 
        v-show="showPop"
        v-click-outside="clickedOutside"
        class="position-absolute popup-menu"
        :style="cpopupStyle"
        :class="dclass"
        ref="popup"
        @click="simple?togglePopup():null"
        @mouseleave="showPop=hoverMode?false:showPop"
      >
          <slot> </slot>
          <div class=""  @click="togglePopup">
            <slot name="ok"/>
          </div>

        <div class="position-absolute " :style="ctriangleStyle" v-if="!noTriangle">


          <svg style="transform-origin:bottom right;transform:scale(0.4)"
          width="72" height="43" viewBox="0 0 72 43" fill="none" >
          <path d="M44.8107 5.14177C55.0163 -4.62228 71.9997 2.59617 
          71.9997 16.7203V42.7981C71.9997 42.9096 71.9093 43 71.7978 43V43H1.03406C0.738772 
          43 0.686858 42.5783 0.973333 42.5067V42.5067C0.991058 42.5022 1.00574 42.4971 
          1.02209 42.489C1.38423 42.3079 6.11617 39.9419 9 38.5C10.6039 37.698 28.501
          20.7457 44.8107 5.14177Z" fill="white"/>
          </svg>



        </div>
      </div>
   </transition>
</div>

</template>

<script>

const sides = ['top','bottom','left','right']
function opp(side){
  const i = sides.indexOf(side)
  const mod = i %2
  return sides[i+1-mod*2]
}
// import { createPopper } from '@popperjs/core';
import ClickOutside from 'vue-click-outside'
export default {
  props:{
    dclass:{default:''},
    side:{default:'bottom'},
    popupStyle:{type:Object,default:() => ({})},
    triangleStyle:{type:Object,default:() => ({})},
    noTriangle:{type:Boolean,default:false},
    simple:{type:Boolean,default:false},
    fromRight:{type:Boolean,default:false},
    menuWidth:{},
    hoverMode:{type:Boolean,default:false,}
  },
  directives:{ClickOutside},
  data(){
    return{
      showPop:false,
      popper:null,
    }
  },
  mounted(){
/*
    const button = this.$refs.button
    const popup = this.$refs.popup

    const s = this.side
 
    let skid = 0
    let dist = 20
    if(s == 'left' || s == 'right'){
      skid = -24
    }
    
      this.popper = createPopper(button, popup, {
                placement: 'bottom-end',
                strategy:'fixed',
                modifiers:[
                    {
                        name: 'flip',
                        enabled: false,
                    },
                    {
                      name:'offset',
                      options: {
                          offset: [skid,dist]
                      },
                    },
                    {
                      name: 'preventOverflow',
                      options: {
                        mainAxis: false, // true by default
                      }
                    },
                ]
      });
      
   */

      
  },
  updated(){
    if(this.popper){
      this.popper.update();
    }
  },
  computed:{
    cpopupStyle(){
      const style =  {}
      const s = this.side
      const poff = 'calc(100% + 18px)'
      const soff = this.noTriangle? '2px': '12px'

      if(s=='top'||s=='bottom'){
        style[opp(s)] = poff

        const alignSide = !this.fromRight? 'right': 'left'
        style[alignSide] = soff

      }else{
        style[opp(s)] = poff
        style.top = soff
      }
      if(this.menuWidth){
        style.width = this.menuWidth || '360px'
      }
      return {...style,...this.popupStyle}
    },
    ctriangleStyle(){
      const style =  {}
      const s = this.side
      const poff = '100%'
      const soff = '0px'

      const bgColor = 'white' || ' #ebedf1'

      sides.forEach(ts=>{
        style['border-'+ts] = 'var(--size) solid '+(ts==(s)?bgColor:'transparent')
      })

      if(s=='top'||s=='bottom'){
        style[(s)] = poff
        style.right = soff
      }else{
        style[(s)] = poff
        style.top = soff
      }

      return {...style,...this.triangleStyle}
    },
  },
  methods:{
    async togglePopup(){
      await this.$wait(20)
      this.showPop = !this.showPop
    },
    clickedOutside(){
      if(this.showPop){
        this.$emit('close')
      }
      this.showPop = false;
    },
    handleHover(){
      if(this.hoverMode){this.showPop=true}
    }
  },
  watch:{
   
  }
}
</script>

<style>
.popup-menu{
    border-radius: 8px;
    border-top-right-radius: 0;
    background-color: white;
    box-shadow: 0 0 12px rgba(6, 12, 24, 0.7);
    z-index: 99;
    /* border: 2px solid var(--sec); */
     
}

@keyframes come-up{
  0%{opacity: 0;}
   
  100%{}
}
.popped-up > button{
  filter: sepia() hue-rotate(180deg);
}

.slide-down-enter-active,.slide-down-leave-active{
  transition: all 0.4s;
}
.slide-down-enter,.slide-down-leave-to{
    transform: translateY(-20%);
    opacity: 0.0;
}

.parrow-up {
  --size: 12px;
  width: 0; 
  height: 0; 
  
}
</style>