export default {
    home:{
        lines:[
            `Non-intrusive.,     Swift.,      Scientific.,      Unbiased.,       Free.`,

        ],
        plans:{
            yourself:`
            Recommended for those who need to understand their Insurance needs before seeking help from financial advisors or buying insurance policies/ financial products
all by themselves.</br></br>Get started to generate the comprehensive and personalized need analysis report which <span class="bold-desc">takes less than a minute</span>, <span class="bold-desc">doesn't require the email id/phone no</span>, and now <span class="bold-desc">costs as low as ₹499</span>. <span class="font-weight-bold text-pri d-block mt-2 pulsar" style="font-size:1.1em">
The need analysis for term insurance is FREE!</span>
            `,
            others:`
            Recommended for insurance agents and financial advisors who need assistance in better understanding the insurance and financial needs of their customers before selling insurance policies/financial products.
            </br></br>
            <span class="font-weight-bold text-pri d-block mt-2 pulsar" style="font-size:1.1em">
Register for FREE </span>. <span class="bold-desc">No monthly charges</span> and additional <span class="bold-desc">credits can be bought </span>as required <span class="bold-desc">at heavily subsidized rates</span>. And most importantly, <span class="bold-desc">doesn't require the email id / phone number of your customer</span>.
            `,
            many_others:`
            Recommended for bigger players in the business of selling insurance policies and financial products. The API-first tool can be seamlessly integrated and need analysis
can be white-labeled
</br></br>
Offered in a SaaS model, the monthly <span class="bold-desc">subscription starts with as low as ₹10,000 </span>and <span class="bold-desc">doesn't require any Personally Identifiable Information (PII)</span> to be shared. <span class="font-weight-bold text-pri d-block mt-2 pulsar" style="font-size:1.1em">Contact Us for the demo and 15-days FREE TRIAL</span>
            `
        },
        disc:`
        ProtectMeWell™ is an Insurance recommendation service and intends to provide clear
        and unbiased information about the financial products listed on this website. The information and
        data are generic in nature. Our efforts are to offer unbiased, accurate and responsible data to the
        best of our ability. The need-based analysis provided by us on this website maybe believed to be
        reliable, but ProtectMeWell™ does not accept any responsibility (or liability) for errors of fact or
        opinion. Visitors and Users have the right to choose the product(s) that in their opinion suits them
        the most and are advised to use their discretion at all times in such matters. ProtectMeWell™ is
        not regulated by IRDAI and does not have any tie up with Insurance Company or Third-party
        Administrator
        `,
        testimonies:[
            {
                imgUrl:'images/home/aditya.jpg',
                name:'Aditya Bhalla',
                des:'Manager - MDM at Axtria - Ingenious Insights',
                message:`
                    Highly recommend to anyone who is planning of
                    insurance cover but not sure about the size of the coverage.
                `
            },
            {
                imgUrl:'images/home/samThomas.jpg',
                name:'Sam Thomas',
                des:'Technical Lead Software - SLK',
                message:`
                    Perfect Prediction for Your Insurance Coverage...
                `,
            },
            {
                imgUrl:null,
                name:'Prakash N Desai',
                des:'Life Insurance Advisor',
                message:`
                    ProtectMeWell is very nice and I have tried 3/4 times
                    for different ages and financial status and it recommends
                    required term assurance accurately. Thanks for giving me
                    such engine
                `,
            }
        ]
    },
    form:{
        termsAndConditions:`
        These terms and conditions ("T&Cs") are applicable to your use of 'ProtectMeWell™ Credits'. ProtectMeWell™ Credits is the branding for the closed pre-paid stored value issued by PMWELL TECHNOLOGIES PRIVATE LIMITED company registered under the Companies Act, 2013 having its registered office at #37/E, 2nd Flr, Beside Surana Clg, South, End Rd, Nr. South End Crcl, Basavanagudi, Bangalore, Bangalore, Karnataka, India, 560004.
        </br></br>By registering for or using ProtectMeWell™ Credits, you signify your agreement to be bound by these T&Cs.
        </br></br>Registration for ProtectMeWell™ Credits. To register for ProtectMeWell™ Credits, you must: </br>(a) be an Indian citizen, resident in India; </br>(b) have attained the age of 18 years; and </br>(c) have a valid ProtectMeWell™ account. </br></br>The ProtectMeWell™ Credits accounts can be availed by you as follows:
        </br></br>1.</t>At the time of registering and opening a ProtectMeWell™ account, you will be required to provide minimum details including mobile number, email address (verified through OTP).
        </br></br>2.</t>You will be allowed to use only one ProtectMeWell™account at any given point of time.
        </br></br>3.</t>ProtectMeWell™ Credits are reloadable and can be used only for purchasing the services provided by ProtectMeWell™ on ProtectMeWell™ Website (https://www.protectmewell.com) in a manner prescribed under these T&Cs and as communicated by ProtectMeWell™ from time to time.
        </br></br>4.</t>The ProtectMeWell™ Credits are transferable to the extent of transfer to any other another ProtectMeWell™ Account User only.
        </br></br>5.</t>ProtectMeWell™ may, basis risk perception, lower the limits mentioned hereinabove at any given point of time and intimate the ProtectMeWell™ Account holders by changing these T&Cs.
        </br></br>You agree and consent to ProtectMeWell™ collecting, storing, sharing and processing information (including sensitive personal information) related to you to third parties/service providers for the purposes as set out in these T&Cs and for providing you with the Services of ProtectMeWell™, offers and/or information.
        </br></br>By proceeding to register with ProtectMeWell™, you represent and confirm that you are not a politically exposed person and have not been entrusted with prominent public functions in a foreign country as per the Master Directions on Know Your Customer issued by the RBI. If you are such a politically exposed person, please reach out to us at info@protectmewell.com before registering with ProtectMeWell™.
        </br></br>ProtectMeWell™ may appoint and use the services of authorized agents as per the applicable laws to perform certain services on our behalf, including KYC related services. The details of these agents will be provided/ made available to you basis your service request to us. Please reach out to our customer care team if you require any other information in this regard.
        </br></br>Load your ProtectMeWell™ Credits: Credits can be loaded through online payment methods such as domestic credit/debit card and net banking. However, you will not be able to use prepaid cards, EMI, cards issued outside India or a combination of cards to add/load amount to your ProtectMeWell™ Credits. Except as provided hereunder, credits in your ProtectMeWell™ Account will not be refunded to you under any circumstances. No refund will be provided in cash, at any point of time. No interest will be paid on your ProtectMeWell™ credits account at any point of time.
        </br></br>1.</t>Permissible Use.
        </br></br>ProtectMeWell™ Credits may only be redeemed towards the purchase of ProtectMeWell™ services offered by ProtectMeWell™ on ProtectMeWell™ Website. Amount of purchases will be deducted from your ProtectMeWell™ credits. No cash withdrawal will be permitted at any point in time, to any ProtectMeWell™ account.
        </br></br>2.</t>Fees and Charges
        </br></br>ProtectMeWell™ reserves its right, in its sole discretion and without any prior notice, to impose and/or revise charges/commissions in relation to the use of your ProtectMeWell™ account. The current charges for your ProtectMeWell™, are as follows:
        </br></br>3.</t>Forfeiture/Suspension/Closure
        </br></br>Expiry and Forfeiture
        </br> </br>ProtectMeWell™ Credits will be valid for an indefinite period; any changes to the same will be communicated by updating these terms and conditions. In the event ProtectMeWell™ imposes a validity period of the ProtectMeWell™ Credits, the unused balances after the expiry of such account will be forfeited in accordance with the applicable regulations.
        Suspension
        </br></br>You are obligated to use the ProtectMeWell™ Credits only as per the provisions of these T&Cs and norms prescribed by ProtectMeWell™ from time to time. Any activity in contravention of such provisions may lead to suspension of your ProtectMeWell™ account. During such period, your ability to use the ProtectMeWell™ Credits will be restricted, as prescribed by ProtectMeWell™.
        Closure
        </br></br>Further you will have the option of closing such ProtectMeWell™ account. In the event of closure of your ProtectMeWell™ account, any and all ProtectMeWell™ Credits available in your ProtectMeWell™ account will be forfeited by ProtectMeWell™.
        </br></br>4.</t>ProtectMeWell™’s  Role.
        </br></br>You agree and acknowledge that ProtectMeWell™ is only a facilitator and is not and cannot be a party to or control in any manner any transactions for purchasing the services provided by ProtectMeWell™ on ProtectMeWell™ Website (https://www.protectmewell.com). ProtectMeWell™ shall not mediate or resolve any dispute or disagreement between you and the banks, sellers, merchants. While ProtectMeWell™ endeavors to process your ProtectMeWell™ Credits transactions in a timely manner, please note that there may be certain scenarios when the transactions don't immediately reflect in your ProtectMeWell™ account.
        </br> </br>5.</t>Fraud.
        </br></br>Where the email address being used to access ProtectMeWell™ Credits gets hacked/inoperative, you are required to immediately inform ProtectMeWell™ about the same. Such complaint can be reported at the customer care as mentioned under these T&Cs. ProtectMeWell™ reserves the right to restrict transaction on such account, in order to prevent any unauthorized usage of the ProtectMeWell™ Credits. In case of change in email address, mobile number or the information/documents provided to ProtectMeWell™ at the time of onboarding, you are required to intimate ProtectMeWell™immediately. ProtectMeWell™ will not be liable for any fraudulent transactions undertaken prior to intimation to ProtectMeWell™about the change in your details.
        </br></br>ProtectMeWell™ may suspend or discontinue your access/use  to or of your ProtectMeWell™Credits, for any reason at any time without written notice to you. In addition, ProtectMeWell™ may suspend your use of your ProtectMeWell™ Credits if it suspects that such transaction or your ProtectMeWell™ Credits is being or is likely to be used without authorization, fraudulently, suspiciously or is otherwise unusual based on prior transaction activity / history and as per our internal fraud controls.
        </br></br>6.</t>Governing Law and Jurisdiction.
        </br></br>These terms and conditions are governed by and construed in accordance with the laws of India. You agree to submit to the exclusive jurisdiction of the courts at Bangalore, Karnataka. You agree to indemnify ProtectMeWell™ for all claims brought by a third party against it or its affiliates arising out of or in connection with a breach of any of these T&Cs and your use of ProtectMeWell™ Credits.
        </br></br>7.</t>Limitation of Liability.
        </br></br>ProtectMeWell™ CREDITS, AND ALL INFORMATION, CONTENT, MATERIALS, AND SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU ARE PROVIDED BY ProtectMeWell™ ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. ProtectMeWell™ DISCLAIMS ALL RESPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE OPERATION OF ProtectMeWell™ CREDITS, INCLUDING WITHOUT LIMITATION, ANY EXPRESS OR IMPLIED REPRESENTATION OR WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. IF APPLICABLE LAW DOES NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS. IN NO EVENT WILL ProtectMeWell™ OR ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, ARISING OUT OF OR RELATED TO ProtectMeWell™ CREDITS.
        </br></br>8.</t>General Terms.
        </br></br>ProtectMeWell™ reserves the right to make changes to these T&Cs governing ProtectMeWell™ Credits, at any time, in its sole discretion. All terms and conditions are applicable to the extent permitted by law. Please refer the 'Last Updated' legend in theses T&Cs for the date of the last update to these T&Cs. By continuing usage of ProtectMeWell™ Credits, You agree to be bound by such changes/revisions.
        </br></br>9.</t>Contact Information
        </br></br>Information can also be attained from the FAQ page on ProtectMeWell™ Website (https://www.protectmewell.com). You could direct any questions, comments or complaints related to ProtectMeWell™ at info@protectmewell.com.
        </br>
        </br>Last updated on: 1-Jan-2021
        `,
        terms:`Protect Me Well™ intends to provide clear and unbiased information about the financial products listed on this website. The information and data are generic in nature. Our efforts are to offer unbiased, accurate and responsible data to the best of our ability. The need-based analysis provided by us on this website maybe believed to be reliable, but Protect Me Well™ does not accept any responsibility (or liability) for errors of fact or opinion. Visitors and Users have the right to choose the product/s that in their opinion suits them the most and are advised to use their discretion at all times in such matters.

        </br></br>It is hereby informed to all that Protect Me Well™ is not the intermediary/sub-intermediary of the entities whose products are dealt with in this website. Though endeavour is made to make correct need based analysis on the basis of the information provided by the entities whose products are dealt with in this website and the information provided by the Users, it is made abundantly clear that Protect Me Well™ , its directors, shareholders, officers and employees and Protect Me Well™ are in no way responsible to or liable for any one for his/her investment decision, and every prospect/ investor shall be solely responsible for the consequences of his/her decision.`
    },
    about:{
        who:`
        We are a new kind of Insurtech company – one that enables responsible individuals to
        understand their Insurance needs to better protect themselves and their families.
        We believe that everybody needs to devise an informed strategy to face the financial
        and health related uncertainties. Our objective is to provide you a clear and
        accurate recommendation on your insurance needs.
        `,
        what:`
        We are a team of Insurance Industry experts called Actuaries -
        we have designed and developed life and health Insurance products for
        global Insurance markets. We exactly understand the purpose of
        each Insurance product feature and how it can be best utilized
        to cover health and financial risk.

        At ProtectMeWell™, we have developed a proprietary algorithm to
        recommend the type and level of Insurance coverage based on your
        personal and financial details.
        `
    },
    faq:{
        questions:[
            {
                q:`How does ProtectMeWell™ work?`,
                a:`
                <span class="logo-text">ProtectMeWell™</span> gives you a simplified
                 view of your insurance needs that
                can guide you in getting the most appropriate level of insurance coverage
                from any insurer in the market.

                We'll ask a bit about you and your family along with your financial details.
                Then, our proprietary algorithm will determine how long and how much Insurance
                coverage is needed for you to ensure financial stability especially due to any
                unforeseen event in your life.

                This proprietary algorithm is designed
                and developed by qualified industry experts (read actuaries) who
                developed numerous insurance products and
                services for global Insurance markets.`
            },
            {
                q:`How do you estimate my recommended coverage?`,
                a:`
                  We utilize your information to gauge your risk profile
                  and recommend insurance product(s) that would minimize the financial
                  impact of the risks. The coverage recommendation calculation
                  takes your details of family, household income, assets, and
                  loans taken and combine them with the lifestyle expenses
                  that you would incur. We suggest you provide correct and
                  detailed information so that we can provide
                  you appropriate recommendations.
                `
            },
            {
                q:`  What types of Insurance coverage is recommended?`,
                a:`
                    Currently, our recommendation is focused on term life plan coverage,
                    retirement corpus, critical illness cover and medical insurance which
                    ensures financial stability in the event of unforeseen events.
                    We are working on including other insurance coverage types
                    in our recommendation to provide a holistic portfolio.
               `
            },
            {
                q:`How do I use your recommendation?`,
                a:`
                The products and coverages recommended by
                <span class="logo-text">ProtectMeWell™</span>  are
                 readily available in your Insurance market. Therefore, you
                  can directly contact any insurance agent/advisor or company
                   to buy the recommended Insurance coverage.
                `
            }
        ]

    },
    HowItWorks:{
        intro:`
        We strongly believe that insurance is meant to protect individuals
        and families against financial risks arising from various life situations.
        Through our combined experiences of working across geographies
        over several decades, we realise that insurance, though extremely
        crucial, is amongst the least understood financial products.
        There are very few who know what type of coverage they should buy
        and how much should be that coverage. And this is exactly where
        <span class="logo-text">ProtectMeWell™</span> comes in!

        `,
        life:`
        Life Events and Life Stages

        From an insurance point of view, life can be broadly divided into four stages.
        The risks associated at each stage are different and hence the protection needs
        also change with life stages. Also, some risks span over multiple stages – health
        risks are the classical example, which span across all four stages.
        `,
        mechanism:`

        To help you identify the accurate insurance needs, a group of
        actuaries has devised the protection-focussed web-based tool,
        <span class="logo-text">ProtectMeWell™</span>. This is a non-intrusive, swift, scientific and unbiased
        tool. The underlying algorithm is comprehensive, but the tool is very
        intuitive and easy to use. The algorithm is based on factors like
        household income, household expenses, assets, and liabilities.

        `,
        termPlan:`


        <span class="logo-text">ProtectMeWell™</span> recommends a term life insurance plan, which will provide
         protection starting the first job until your retirement. It is an effective
          tool for individuals who have financial dependent(s) and outstanding loans
           and aim at covering for future expenses in case of an untimely death. <br><br>

        Recommendation from <span class="logo-text">ProtectMeWell™</span> tool will differ based on your personal
         and financial profile. Depending on the household income sufficiency and
         some other factors, the tool can recommend buying or not buy a term plan,
          as shown below in two distinct scenarios.


        `,
        medical1:`
            Critical Illness can derail the financial planning owing to the huge
            treatment expenses and loss of income during the treatment and recovery phase.
            The rising incidence of lifestyle diseases and medical costs makes it
            imperative to buy a critical illness cover <br><br>

            <span class="logo-text">ProtectMeWell™</span> recommends the Insurance coverage for medical
            expenses (after factoring in medical inflation) and loss in income
            due to critical illness. The size of the cover recommended factors
            personal and financial profile and varies with income and expense
            levels of individuals and city of residence. The recommended duration
            of cover is based on affordability.
        `,
        medical2:`
            While the Critical Illness Plans covers individuals against
            financial loss due to grave illnesses, it is financially wise
            to protect the family against other medical emergencies typically
            resulting in hospitalization. The Family Floater Medical
            Insurance Plans are designed to serve this need <br><br>

            <span class="logo-text">ProtectMeWell™</span> tool recommends a Family Floater Medical Insurance
            plan of an appropriate size while accounting for number
            of family members, income levels and city of residence
            along with some other factors. These insurance plans are
            typically available for one year and we recommend that
            these are renewed yearly with an appropriate cover based
            on the current financial and personal profile.


        `,
        annuity:`
        <span class="logo-text">ProtectMeWell™</span> recommends Medical & Critical Illness Insurance plans,
            which will provide protection against medical emergencies and grave illnesses,
            respectively. The annuity plans thus bought would cover the expenses of
            the individual and the spouse during the golden years for the rest of their lives <br><br>

            Recommendation from <span class="logo-text">ProtectMeWell™</span> tool recommends the retirement
            corpus based on the personal and financial profile of the individual.
            The recommended amount would vary on the current income and expense
            levels along with expected lifetime of the individuals and a few
            other parameters. It is important to highlight that both earning
            and non-earning members need insurance cover against critical
            illnesses as it is evident from the scenarios below
        `

    },
    footer:{
        address:`
            37/E, 2 nd Floor, Beside Surana College,
            South end road, Basavanagudi, Bangalore – 560004
        `
    },
    insuranceChecklist:{
        questions:[
            { q: `Did you choose the appropriate cover i.e “Sum Assured”?` },
            { q: `Did you choose the appropriate duration?` },
            {
              q: `Did you choose a policy that allows you to increase the Sum Assured?`
            },
            {
              q: `Did you choose a policy that allows you to decrease the additional Sum Assured?`
            },
            {
              q: `Did you choose a policy that offers maturity benefits like the Return of Premium (RoP) at the end of the policy term?`
            },
            { q: `Did you opt for Accidental Death Benefit Rider? (Add on)` },
            { q: `Did you opt for Critical Illness Rider? (Add on)` },
            { q: `Did you opt for Accidental Disability Rider? (Add on)` },
            {
              q: `Did you opt for Waiver of Premiums Rider on Critical Illness? (Add on)`
            },
            {
              q: `Did you opt for Waiver of Premiums Rider on Disability? (Add on)`
            }


        ],
        ending: `Download report to see detailed  answers and also to know about other considerations`
    }
}


